<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-button
      class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
      @click="$router.go(-1)"
    >
      <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
    </b-button>
    <b-button
      class="btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2"
      @click="Submit()"
    >
      <i class="far fa-check mr-50" /> {{ $t('approve') }}
    </b-button>

    <b-row class="match-height">
      <b-col md="6">

        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('details') }} {{ $route.params.id }}
            </h3>
          </div>

          <div class="p-2">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('orderNumber') }} :
                  </template>

                  <p class="mt-50 font-weight-bolder">
                    G23123102
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('customerCode') }} :
                  </template>
                  <div class="mt-50 font-weight-bolder">
                    <router-link
                      :to="{name : 'admin-traking'}"
                    >
                      123456
                    </router-link>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('status') }} :
                  </template>
                  <p class="mt-50 mb-0 badge badge-light-success">
                    {{ $t('operationCompleted') }}
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    จำนวนหยวนที่ถอน :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    ¥ 453.00
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('exRate') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    4.90
                  </p>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label-for="h-paecelCode"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    {{ $t('transactionDate') }} :
                  </template>
                  <p class="mt-50 font-weight-bolder">
                    05/11/2023 14:16
                  </p>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="6">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('relatedAmountList') }}
            </h3>
          </div>

          <div class="pl-2 pr-2">
            <b-table
              responsive="sm"
              :fields="fieldsRelevant"
              :items="Transaction"
              show-empty
            >
              <template #empty="">
                <b-table-empty-slot>
                  <div class="text-center my-2">
                    <img
                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                      alt="empty"
                      height="60px"
                    >
                    <p class="text-muted">
                      No Data
                    </p>
                  </div>
                </b-table-empty-slot>
              </template>

              <template #cell(amount)="data">
                <span class="text-success">
                  {{ data.value }}
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
  },
  data() {
    return {
      isActive: true,
      file: null,
      parcelCode: null,
      remark: null,
      Transaction: [],
      parcelList: [
        {
          value: '1',
          text: '1',
        },
        {
          value: '2',
          text: '2',
        },
        {
          value: '3',
          text: '3',
        },
      ],
      fieldsRelevant: [
        {
          key: 'created_at', label: 'วันที่ทำรายการ', thStyle: { width: '20%' }, formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: 'รายละเอียด', thStyle: { width: '60%' } },
        {
          key: 'amount', label: 'จำนวน', thStyle: { width: '20%' }, formatter: value => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    Submit() {
      // eslint-disable-next-line no-alert
      alert('Submit')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
